"use client";

import React, { useEffect } from "react";

import MediaPlayer, {
  MediaPlayerStates,
} from "@customer-ui/components/MuxMediaPlayer";
import { Media } from "@customer-ui/types";
import SwipeableContainer from "@microsite/components/molecules/SwipeableContainer";
import { MediaPlayerControlProps } from "@microsite/components/organisms/MediaPlayerControls/types";
import modulo from "@utils/fn/modulo";
import useListenersState from "@utils/hooks/useListenersState";
import { StoriesVariation } from "@utils/microsites/personalization/sectionsVariationsEnums";

const MediaPlayerControlsBasic = React.lazy(
  () => import("@microsite/components/organisms/MediaPlayerControlsBasic"),
);

const MediaPlayerControlsCarousel = React.lazy(
  () => import("@microsite/components/organisms/MediaPlayerControlsCarousel"),
);

const MediaPlayerControlsInstagram = React.lazy(
  () => import("@microsite/components/organisms/MediaPlayerControlsInstagram"),
);

interface MediaPlaylistProps {
  /* The list of assets to be rendered */
  playlist: Media[];

  /* The controls style to be applied to the playlist */
  style: StoriesVariation;

  /* Current state of the media player */
  state?: MediaPlayerStates;

  /* Callback - When advancing to the next playlist */
  onNextPlaylist?: () => void;

  /* Callback - When receding to the previous playlist */
  onPreviousPlaylist?: () => void;

  /* Flag - Loop the media on ending */
  loopMedia?: boolean;

  /* Flag - Loop the playlist on ending */
  loopPlaylist?: boolean;

  /* Flag - Whether to allow playlist to be swipeable */
  swipeable?: boolean;
}

const CONTROL_CONTENT = {
  [StoriesVariation.BASIC]: MediaPlayerControlsBasic,
  [StoriesVariation.CAROUSEL]: MediaPlayerControlsCarousel,
  [StoriesVariation.INSTAGRAM]: MediaPlayerControlsInstagram,
} as Record<StoriesVariation, React.FC<MediaPlayerControlProps>>;

const MediaPlaylist: React.FC<MediaPlaylistProps> = ({
  playlist,
  style = StoriesVariation.INSTAGRAM,
  state = MediaPlayerStates.ACTIVE,
  onNextPlaylist,
  onPreviousPlaylist,
  loopMedia = false,
  loopPlaylist = true,
  swipeable = true,
}) => {
  const [activePlaylistState, setActivePlayslistIndex] =
    useListenersState<number>(0);
  const [upcomingPlaylistState, setUpcomingPlayslistIndex] =
    useListenersState<number>(modulo(1, playlist.length));

  const updatePlaylistIndexes = (
    activeIndex: number,
    upcomingIndex: number,
  ) => {
    setActivePlayslistIndex(modulo(activeIndex, playlist.length) || 0);
    setUpcomingPlayslistIndex(modulo(upcomingIndex, playlist.length) || 0);
  };

  const moveForward = () => {
    const currentIndex = activePlaylistState.current!;

    if (!loopPlaylist && currentIndex === playlist.length - 1)
      return onNextPlaylist?.();

    updatePlaylistIndexes(currentIndex + 1, currentIndex + 2);
  };

  const moveBackward = () => {
    const currentIndex = activePlaylistState.current!;

    if (!loopPlaylist && currentIndex === 0) return onPreviousPlaylist?.();

    updatePlaylistIndexes(currentIndex - 1, currentIndex);
  };

  const currentMedia = playlist[activePlaylistState.current!];
  const upcomingMedia = playlist[upcomingPlaylistState.current!];

  const Controls = CONTROL_CONTENT[style];
  const Container = swipeable ? SwipeableContainer : React.Fragment;

  useEffect(
    () => {
      if (!currentMedia) moveForward();
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentMedia],
  );

  return (
    <Container onSwipeBackward={moveBackward} onSwipeForward={moveForward}>
      <MediaPlayer
        style={style}
        contentType={
          currentMedia?.media?.mediaType || currentMedia?.contentType
        }
        playbackId={currentMedia?.media?.mediaId || currentMedia?.url}
        onEnded={moveForward}
        state={state}
        loop={loopMedia}
      >
        {/* Lazy load media controls */}
        <React.Suspense>
          <Controls
            playlist={playlist}
            activeIndex={activePlaylistState.current!}
            state={state}
          />
        </React.Suspense>
      </MediaPlayer>

      {/* Upcoming media, hidden for preloading */}
      {currentMedia !== upcomingMedia && (
        <MediaPlayer
          style={style}
          contentType={
            upcomingMedia?.media?.mediaType || upcomingMedia?.contentType
          }
          playbackId={upcomingMedia?.media?.mediaId || upcomingMedia?.url}
          state={MediaPlayerStates.PRELOADING}
        />
      )}
    </Container>
  );
};

export default MediaPlaylist;
