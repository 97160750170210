"use client";

import useEmblaCarousel from "embla-carousel-react";
import React, { useCallback, useEffect } from "react";

import cn from "@utils/cn";

interface CarouselProps<T> {
  /* Elements to be rendered */
  items: T[];

  onMoveRegistered?: (move: (direction: number) => void) => void;
  onActiveIndexChanged?: (index: number) => void;

  children: (
    item: T,
    index: number,
    size: number,
    move: (direction: number) => void,
  ) => React.ReactNode;
}

const Carousel = <T,>({
  items: rawItems,
  onMoveRegistered,
  onActiveIndexChanged,
  children,
}: CarouselProps<T>) => {
  const items = [
    ...rawItems,
    ...rawItems,
    ...rawItems,
    ...rawItems,
    ...rawItems,
    ...rawItems,
    ...rawItems,
    ...rawItems,
    ...rawItems,
    ...rawItems,
  ];
  const [emblaRef, emblaApi] = useEmblaCarousel({
    breakpoints: {
      "(min-width: 1px)": { loop: true, align: "center" },
      "(min-width: 1024px)": { align: () => 100 },
    },
  });

  const move = useCallback(
    (direction: number) => {
      if (!emblaApi) return;

      const scrollMethod =
        direction < 0 ? emblaApi.scrollNext : emblaApi.scrollPrev;
      scrollMethod();
    },
    [emblaApi],
  );
  useEffect(
    () => {
      onMoveRegistered?.(move);
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [move],
  );
  const onEmblaUpdated = useCallback(
    () => {
      if (!emblaApi || !onActiveIndexChanged) return;

      onActiveIndexChanged(emblaApi.internalEngine().index.get());
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emblaApi, items.length],
  );
  useEffect(() => {
    if (!emblaApi) return;

    emblaApi.on("select", onEmblaUpdated);
    emblaApi.on("reInit", onEmblaUpdated);

    onEmblaUpdated();

    return () => {
      emblaApi.off("select", onEmblaUpdated);
      emblaApi.off("reInit", onEmblaUpdated);
    };
  }, [emblaApi, onEmblaUpdated]);

  return (
    <div className="embla" ref={emblaRef}>
      <div className={cn("embla__container")}>
        {items.map((item, index) => (
          <div
            className="embla__slide basis-auto"
            key={JSON.stringify({ item, index })}
          >
            {children?.(item, index, items.length, move)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
