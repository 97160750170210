"use client";

import React, { useContext, useEffect } from "react";

import Overlay from "@customer-ui/components/LoadingOverlay";
import Typography from "@customer-ui/components/Typography";
import LoaderWithLogo from "@microsite/components/molecules/LoaderWithLogo";
import useCartState from "@microsite/state/cart";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts";

const RedirectingOverlay = () => {
  const isRedirecting = useCartState((state) => state.isRedirecting);
  const clearRedirecting = useCartState((state) => state.clearRedirecting);
  const { personalization } = useContext(CampaignSuperfiliateMicrositeContext);

  const mediaId = personalization?.branding?.images?.altLogoMedia?.mediaId;

  useEffect(
    () => {
      if (isRedirecting) return;

      /**
       * Given the async nature of the cleanup function, we need to ensure that the overlay is cleared
       * after a certain amount of time to prevent it from being stuck on the screen when consumers
       * decide to navigate back to the previous page
       *
       * Reference: https://github.com/facebook/react/pull/17925
       */
      setTimeout(clearRedirecting, 8000);
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRedirecting],
  );

  if (!isRedirecting) return null;

  return (
    <Overlay loading={isRedirecting} opacity={80} animate={false} fullScreen>
      <div className="absolute inset-0 bg-primary"></div>

      <div className="relative">
        {mediaId && <LoaderWithLogo mediaId={mediaId} />}

        <Typography.Header4 className="absolute inset-0 top-56 text-center text-primary-content">
          Almost There! Hang Tight!
        </Typography.Header4>
      </div>
    </Overlay>
  );
};

export default RedirectingOverlay;
