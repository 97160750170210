"use client";

import { FC, PropsWithChildren, useEffect } from "react";

import VariablesProvider from "@microsite/components/molecules/VariablesProvider";
import PageContent from "@microsite/components/templates/PageContent";
import { ProductsProvider } from "@microsite/contexts/products";
import discountToString from "@microsite/lib/discountToString";
import useShopState from "@microsite/state/shop";
import useSuperfiliateState from "@microsite/state/superfiliate";
import {
  CampaignSuperfiliate,
  Shop,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts";
import { PageProps } from "@utils/microsites/types/microsite";

type Props = PropsWithChildren<PageProps>;

const Layout: FC<Props> = (props) => {
  const { campaignSuperfiliateMicrosite } = props;
  const { campaignSuperfiliate, shop } = campaignSuperfiliateMicrosite;
  const { campaign, code, customer } = campaignSuperfiliate;

  const setShop = useShopState((state) => state.setShop);
  const setSuperfiliate = useSuperfiliateState(
    (state) => state.setSuperfiliate,
  );

  const allSfVariables = {
    sfShopName: shop.name,
    sfFirstName: customer.firstName,
    sfLastName: customer.lastName,
    sfCode: code,
    sfLeadDiscount: discountToString(campaign.campaignReward!.leadDiscount),
    sfMinimumCartValue: campaign.campaignReward!.leadDiscountMinValue,
  };

  useEffect(
    () => {
      setShop(shop as Shop);
      setSuperfiliate(campaignSuperfiliate as CampaignSuperfiliate);
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <CampaignSuperfiliateMicrositeContext.Provider
      value={campaignSuperfiliateMicrosite}
    >
      <ProductsProvider>
        <VariablesProvider variables={allSfVariables}>
          <PageContent {...props} />
        </VariablesProvider>
      </ProductsProvider>
    </CampaignSuperfiliateMicrositeContext.Provider>
  );
};

export default Layout;
