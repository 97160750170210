"use client";

import dynamic from "next/dynamic";
import React, { FC, PropsWithChildren } from "react";

import RedirectingOverlay from "@microsite/components/molecules/RedirectingOverlay";
import HeaderSection from "@microsite/components/organisms/HeaderSection";
import ImageSection from "@microsite/components/organisms/ImageSection";
import MarqueeSection from "@microsite/components/organisms/MarqueeSection";
import MediaWithTextSection from "@microsite/components/organisms/MediaWithTextSection";
import ProductSection from "@microsite/components/organisms/ProductSection";
import ReviewSection from "@microsite/components/organisms/ReviewSection";
import SectionAccordion from "@microsite/components/organisms/SectionAccordion";
import SectionDynamic from "@microsite/components/organisms/SectionDynamic";
import ShowcaseImagesSection from "@microsite/components/organisms/ShowcaseImagesSection";
import StoriesSection from "@microsite/components/organisms/StoriesSection";
import TextSection from "@microsite/components/organisms/TextSection";
import VideoSection from "@microsite/components/organisms/VideoSection";
import { SectionProvider } from "@microsite/contexts/section";
import usePromotionBannerEnabled from "@microsite/features/promotion/hooks/usePromotionBannerEnabled";
import orderMicrositeSections, {
  PersonalizationSectionWithPseudoId,
} from "@utils/microsites/personalization/orderMicrositeSections";
import { PageProps } from "@utils/microsites/types/microsite";

const SectionFooter = dynamic(
  () => import("@microsite/components/organisms/SectionFooter"),
);

const PromotionBanner = dynamic(
  () => import("@microsite/components/organisms/AppPromotionBanner"),
);

const SECTION_KIND_COMPONENT_MAP: Record<
  string,
  React.FC<{ sectionData: PersonalizationSectionWithPseudoId }> | null
> = {
  header: HeaderSection,
  stories: StoriesSection,
  review: ReviewSection,
  media_with_text: MediaWithTextSection,
  image: ImageSection,
  video: VideoSection,
  accordion: SectionAccordion,
  product: ProductSection,
  marquee: MarqueeSection,
  showcase_images: ShowcaseImagesSection,
  text: TextSection,
  dynamic: SectionDynamic,
};

const PageContent: FC<PropsWithChildren<PageProps>> = ({
  campaignSuperfiliateMicrosite,
}) => {
  const reorderableSections = orderMicrositeSections(
    campaignSuperfiliateMicrosite,
  );

  const promotionBannerEnabled = usePromotionBannerEnabled();

  return (
    <>
      <div className="relative bg-base">
        {promotionBannerEnabled && <PromotionBanner />}

        {reorderableSections.map((section, idx) => {
          const Section = SECTION_KIND_COMPONENT_MAP[section.kind] || null;

          return (
            Section && (
              <SectionProvider key={`${section.kind};${idx}`} section={section}>
                <Section key={`${section.kind};${idx}`} sectionData={section} />
              </SectionProvider>
            )
          );
        })}

        <SectionFooter />
      </div>
      <RedirectingOverlay />
    </>
  );
};

export default PageContent;
